<!--
 * @Descripttion: 添加专家
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-06 16:04:43
-->
<template>
    <div>
        <van-nav-bar title="添加专家" left-arrow @click-left="$router.go(-1)" />

        <form id="add-form">
            <div>
                <i class="icon-code wh05 mar-r2" style="background-size:80% auto"></i>
                <p>专家编号</p>
                <input type="text" placeholder="请输入" v-model="addExpertData.code" required>
            </div>
            <div>
                <i class="icon-name wh05 mar-r2"></i>
                <p>专家姓名<span style="color:red">*</span></p>
                <input type="text" placeholder="请输入" v-model="addExpertData.name">
            </div>
            <div>
                <i class="icon-mobile wh05 mar-r2"></i>
                <p>手机号<span style="color:red">*</span></p>
                <input type="text" placeholder="请输入" v-model="addExpertData.mobile" @blur="mobileOnBlur">
            </div>            
            <div>
                <i class="icon-idcard wh05 mar-r2"></i>
                <p>身份证号</p>
                <input type="tel" placeholder="请输入" v-model="addExpertData.idCard"  @blur="onBlur">
            </div>
            <div>
                <i class="icon-sex wh05 mar-r2"></i>
                <p>性别</p>
                <van-icon :name="addExpertData.sex=='1'?'checked':'circle'" :color="addExpertData.sex=='1'?'#00CCB2':'#BFBFBF'" size="0.5rem"/><span>男</span>
                <van-icon :name="addExpertData.sex=='2'?'checked':'circle'" :color="addExpertData.sex=='2'?'#00CCB2':'#BFBFBF'" size="0.5rem"/><span>女</span>
            </div>
            <div>
                <i class="icon-birthday wh05 mar-r2"></i>
                <p>出生日期</p>
                <input type="text" placeholder="请输入出生日期" readonly v-model="addExpertData.birthday">
            </div>
            <div>
                <i class="icon-addr wh05 mar-r2"></i>
                <p>地址</p>
                <input type="text" placeholder="请输入" v-model="addExpertData.addr">
            </div>
            <div>
                <i class="icon-bank wh05 mar-r2"></i>
                <p>开户行</p>
                <input type="text" placeholder="请输入" v-model="addExpertData.bank">
            </div>
            <div>
                <i class="icon-account wh05 mar-r2"></i>
                <p>银行卡号</p>
                <input type="text" placeholder="请输入" v-model="addExpertData.bankCardNum">
            </div>
            <div class="w-line" style="padding:0" ></div>

            <div>
                <i class="icon-unit wh05 mar-r2"></i>
                <p>单位名称</p>
                <input type="text" placeholder="请输入" v-model="addExpertData.orgName">
            </div>
            <div>
                <i class="icon-job wh05 mar-r2"></i>
                <p>专家职称</p>
                <input type="text" placeholder="请输入" v-model="addExpertData.expertTitle">
            </div>
            <div class="w-line" style="padding:0"></div>

            <div class="mar-t2 mar-b2">
                <i class="icon-profession wh05 mar-r2"></i>
                <p>评审专业<span style="color:red">*</span></p>
                <button class="btn lh5" @click.prevent="toAddMajor()">添加</button>
            </div>
            <div>
              <ul class="w-100">
                <li class="greenLi mar-t2 van-ellipsis" v-for="item in addExpertData.majorList" :key="item.id">
                    {{ item.parentName + "-" + item.majorName }}
                </li>
              </ul>
            </div>
            <div class="w-line" style="padding:0"></div>

            <textarea name="remark" rows="5" class="w-100 pad" v-model="addExpertData.remark" placeholder="在这里填写备注内容…"></textarea>
            <div class="bg" style="height:2rem"></div>

            <p class="whiteBg pad-row pad-col3 fixed-bottom">
                <button class="btn w-100 fw" style="font-size:0.45rem" @click.prevent="onAddSave">保存</button>
            </p>
        </form>
    </div>
</template>

<script>
import { Icon,Dialog} from 'vant'
export default {
    data(){
        return{
            addExpertData:{
                code : '',
                name : '',
                idCard : '',
                sex : '',
                birthday:'',
                mobile : '',
                addr : '',
                bank : '',
                bankCardNum: '',
                orgName:'',
                expertTitle:'',
                majorList:[],
                remark:'',
            },
            orgId:'',//公司id
            openId:'',
            mobileReg: /^1[3|4|5|7|8|9]\d{9}$/,
            orgExternal:{
                id:'',
                serviceStatus:'',
                surplusDays:0,
            },
			workweixin: false,				//企业微信
			openUserid:	"",
        }
    },

    components:{
        [Icon.name]:Icon,
        [Dialog.name]:Dialog,
    },
    created(){
		//企微微信标志
		this.workweixin = this.$ls.get("expert_workweixin_flag")&&"1"==this.$ls.get("expert_workweixin_flag")
		if(this.workweixin){
			this.openUserid = this.$ls.get("expert_workweixin_user").open_userid
		}
		//
        if(this.$ls.get("edCurrentOrgExternal")){
            this.orgExternal = this.$ls.get("edCurrentOrgExternal");
        }
        if(this.$ls.get("addExpertData")){
            this.addExpertData = this.$ls.get("addExpertData");
        }
		//openid
		this.openId = this.$ls.get("openid")
        //
        if(this.$ls.get("edCurrentOrg")){
            let org = this.$ls.get("edCurrentOrg")
            this.orgId = org.id
        }
        // this.$ls.set("openid",this.openId);
        this.getExpertOpenMajors();
    },
    methods:{
        /**
         * @Description: 监听身份证光标移出事件,并获取出生年月及性别
         * @Author: Niklaus
         * @Date: 2021-01-06 10:19:39
         * @Remark： 不考虑超过100岁老人
         */
        onBlur(){
            if(this.addExpertData.idCard =='') return
            const idCardSize = this.addExpertData.idCard.length
            if(idCardSize ==15 || idCardSize ==18){
                let birthday = ''
                if(idCardSize ==15){
                    birthday ='19' + this.addExpertData.idCard.substring(6,8) + "-" + this.addExpertData.idCard.substring(8,10) + "-" + this.addExpertData.idCard.substring(10,12)
                }else if(idCardSize ==18){
                    birthday = this.addExpertData.idCard.substring(6,10) + "-" + this.addExpertData.idCard.substring(10,12) + "-" + this.addExpertData.idCard.substring(12,14)
                }
                if(parseInt(this.addExpertData.idCard.substr(idCardSize-2,1)) % 2 ==0) this.addExpertData.sex = '2'
                else this.addExpertData.sex = '1'
                this.addExpertData.birthday = birthday
            }
        },
        //新增专家方法
        onAddSave(){
            if(this.orgExternal.serviceStatus==null||this.orgExternal.serviceStatus=='00'||this.orgExternal.serviceStatus=='02'){
                Dialog.confirm({
                    title : '提示',
                    message : '本功能需要开通专家记服务才能使用',
                    confirmButtonText : '去开通服务',
                    cancelButtonText: '取消',
                    }).then(()=>{ //确认
                        this.$router.push({path: '/renew'});
                    }).catch(() =>{
                    console.log('取消')
                    })
                    return;
            }

            if(this.onSaveCheck()==false){
                return;
            }
            let expertData = {};
            expertData.orgId = this.orgId;//公司id
            expertData.openId = this.workweixin?this.openUserid:this.$ls.get("openid");
            expertData.num = this.addExpertData.code;//专家编号
            expertData.name = this.addExpertData.name;//专家姓名
            expertData.idCard = this.addExpertData.idCard;//身份证号
            expertData.sex = this.addExpertData.sex;//性别
            expertData.birthDate = this.addExpertData.birthday;//出生日期
            expertData.mobilePhone = this.addExpertData.mobile;//手机号
            expertData.addr = this.addExpertData.addr;//地址
            expertData.bankName = this.addExpertData.bank;//开户行
            expertData.bankCardNum = this.addExpertData.bankCardNum;//银行卡号
            expertData.orgName = this.addExpertData.orgName;//单位名称
            expertData.expertTitle = this.addExpertData.expertTitle;//专家职称
            expertData.remark = this.addExpertData.remark;//备注

            expertData.majorList = this.addExpertData.majorList;//评审专业
            this.$ajax({
                method: "post",
                url: "/gateway/ed/wx/wxEdExpertInfo/add",
                headers: {
                    "X-Access-Token": this.$ls.get("token"),
                },
                params: {
                    openid: (this.workweixin?this.openUserid:this.$ls.get("openid"))
                },
                data: expertData
            }).then((res)=>{
                if(res.data.success){
                    this.$toast({
                        message: '添加专家成功',
                        type: 'success',
                        className: 'div7'
                    })                    
                    //跳转专家列表页
                    this.$router.push({name:'expert'});
                }else{
                    this.$toast.fail(res.data.message)
                }
            });
        },
        //遍历专业树结构，获取选中的数据
        getExpertOpenMajors(){
            let openMajors = [];
            let allData = this.$ls.get("orgExpertMajors");
            if(this.$ls.get("orgExpertMajors")=='' || this.$ls.get("orgExpertMajors") ==null){
                return;
            }
            if(allData.gongchengList.length>0){
                for(let i=0;i<allData.gongchengList.length;i++){
                    let obj = allData.gongchengList[i];
                    if(obj.children.length>0){
                        for(let j=0;j<obj.children.length;j++){
                            let childObj = obj.children[j];
                            if(childObj.checked){
                                let addData = {
                                    majorId : childObj.id,
                                    majorName : childObj.majorName,
                                    parentName: obj.majorName
                                };
                                openMajors.push(addData);
                            }
                        }
                    }
                }
            };
            if(allData.huowuList.length>0){
                for(let i=0;i<allData.huowuList.length;i++){
                    let obj = allData.huowuList[i];
                    if(obj.children.length>0){
                        for(let j=0;j<obj.children.length;j++){
                            let childObj = obj.children[j];
                            if(childObj.checked){
                                let addData = {
                                    majorId: childObj.id,
                                    majorName : childObj.majorName,
                                    parentName: obj.majorName
                                };
                                openMajors.push(addData);
                            }
                        }
                    }
                }
            };
            if(allData.fuwuList.length>0){
                for(let i=0;i<allData.fuwuList.length;i++){
                    let obj = allData.fuwuList[i];
                    if(obj.children.length>0){
                        for(let j=0;j<obj.children.length;j++){
                            let childObj = obj.children[j];
                            if(childObj.checked){
                                let addData = {
                                    majorId : childObj.id,
                                    majorName : childObj.majorName,
                                    parentName: obj.majorName
                                };
                                openMajors.push(addData);
                            }
                        }
                    }
                }
            };
            this.addExpertData.majorList  = openMajors;
        },
        //跳转添加专业
        toAddMajor(){
            this.$ls.set("addExpertData",this.addExpertData);
            this.$router.push({path: '/review/expertAdd'});
        },
        //提交校验
        onSaveCheck(){
            //专家编号不是必填
            // if(this.addExpertData.code.trim()==''){
            //     this.$toast.fail('请输入专家编号！');
            //     return false;
            // }
            if(this.addExpertData.name.trim()==''){
                this.$toast.fail('请输入专家姓名！');
                return false;
            }else if(this.addExpertData.name.length>200){
				this.$toast.fail('专家姓名过长，请修改！');
				return false;
			}
            // if(this.addExpertData.idCard.trim()==''){
            //     this.$toast.fail('请输入身份证号！');
            //     return false;
            // }
            if(this.addExpertData.idCard.trim()!=''&&this.validator(this.addExpertData.idCard)==false){
                 this.$toast.fail('身份证号格式不正确！');
                return false;
            }
            if(this.addExpertData.mobile.trim()==''){
                this.$toast.fail('请输入手机号！');
                return false;
            }
            //手机号
            let re = new RegExp(this.mobileReg)
            if (!re.test(this.addExpertData.mobile)) {
                this.$toast.fail("手机号不正确")
                return false;
            }
            if(this.addExpertData.addr.trim()==''){
                //地址为空，暂不做处理
                // this.$toast.fail('请输入地址！');
                // return false;
            }else if(this.addExpertData.addr.length>100){
				this.$toast.fail('地址过长，请修改！');
				return false;
			}
            if(this.addExpertData.orgName.trim()==''){
                //单位名称为空，暂不做处理
                // this.$toast.fail('请输入单位名称！');
                // return false;
            }else if(this.addExpertData.orgName.length>200){
				this.$toast.fail('单位名称过长，请修改！');
				return false;
			}
			
			if(this.addExpertData.code.length>50){
				this.$toast.fail('专家编号过长，请修改！');
				return false;
			}
			if(this.addExpertData.bank.length>100){
				this.$toast.fail("开户行名称过长，请修改！")
				return false
			}
			if(this.addExpertData.bankCardNum.length>100){
				this.$toast.fail("银行卡号过长，请修改！")
				return false
			}
			if(this.addExpertData.expertTitle.length>200){
				this.$toast.fail("专家职称过长，请修改！")
				return false
			}
			//
            this.getExpertOpenMajors();
            let majorList = this.addExpertData.majorList;//评审专业
            if(majorList.length==0){
                this.$toast.fail('请选择评审专业！');
                return false;
            }
            return true;
        },
        // 校验函数返回 true 表示校验通过，false 表示不通过
        validator(val) {
            var reg = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$/
            if (val.length == "15") {
                return reg.test(val)
            } else if (val.length == "18") {
				//只允许X大写
                // reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
				reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/
                return reg.test(val)
            } else {
                return false
            }
        },
		//手机号失去焦点函数
        mobileOnBlur(){
            let re = new RegExp(this.mobileReg)
            if (!re.test(this.addExpertData.mobile)) {
                this.$toast.fail("请输入正确的手机号码")
                return false;
            }
        },
        //手机号码校验
        mobileValidator(val){
          let mobileReg = new RegExp(/\d{3}-\d{8}|\d{4}-\d{7}|^1(3[0-9]|4[57]|5[0-35-9]|7[0678]|8[0-9])\d{8}$/)
          return mobileReg.test(val)
        },
    }
}
</script>

<style scoped>
#add-form>div{padding: 0.1rem 0.5rem;display: flex; display: -webkit-flex;align-items: center; line-height: 1rem;position: relative;}
#add-form>div>p{width: 2rem; font-weight: 800;}
#add-form>div>i{pointer-events: none;}
#add-form>div>span{display: inline-block; margin-left:0.3rem; margin-right: 0.5rem;}
.lh5{position: absolute; right: 0.5rem; top: 0.1rem;}
.greenLi:not(:first-child){margin-top: 0.2rem;}
</style>